const assert = require('assert');

import { graphql } from 'gatsby';

import * as React from 'react';

import 'katex/dist/katex.min.css';

import { default as AnsiUp } from 'ansi_up';

import { withDocLayout } from '../components/layout';
import {
  Button,
  Container,
  Embed,
  Grid,
  Icon,
  Image,
  Message,
  Segment,
} from 'semantic-ui-react';

import { H1, H2, H3, H4, H5, H6 } from '../components/mdx_blocks/header';
import Highlighter from '../components/mdx_blocks/highlighter';
import { LazyIFrame } from '../components/lazy_iframe';
import { renderMarkdown } from './utils';
import { versionNumberFromDocPath } from '../utils';

import jupyterLogo from '../images/logos/jupyter_logo.svg';
import pythonPoweredLogo from '../images/logos/python_powered_logo.svg';

const ansiUp = new AnsiUp();

function stringOrJoin(content) {
  if (typeof content === 'string') {
    return content;
  }
  return content.join('');
}

interface NotebookCellOutputProps {
  output_type: string;
  data: {};
  'text/plain'?: [string];
  name?: string;
  text?: string;
  widgets_root_path: string;
  widgets_size_map: { string: number };
}

interface NotebookCellProps {
  metadata: any;
  cell_type: string;
  widgets_root_path: string;
  widgets_size_map: { string: number };
  source: [string];
  execution_count?: number;
  outputs?: [NotebookCellOutputProps];
  pathname: string;
}

interface ChildProps {
  children: any;
}

const PaddedMarkdownParagraph = (props: ChildProps) => {
  return <p style={{ marginBottom: '1em' }}>{props.children}</p>;
};

const PaddedMarkdownH1 = (props: ChildProps) => {
  return (
    <div style={{ marginBottom: '1.5em' }}>
      <H1>{props.children}</H1>
    </div>
  );
};

const PaddedMarkdownH2 = (props: ChildProps) => {
  return (
    <div style={{ marginTop: `1em`, marginBottom: '1.0em' }}>
      <H2>{props.children}</H2>
    </div>
  );
};

const MarkdownImage = (props: ChildProps) => {
  return <Image centered src={'.' + props.src} />;
};

interface NotebookCellOutputDisplayDataProps {
  data: {
    'application/vnd.jupyter.widget-view+json': {
      model_id: string;
      version_major: number;
      version_minor: number;
    };
  };
  widgets_root_path: string;
  widgets_size_map: { string: number };
}
class NotebookCellOutputDisplayData extends React.Component<
  NotebookCellOutputDisplayDataProps,
  {}
> {
  public render() {
    if (this.props.data['image/png']) {
      return (
        <Image
          centered
          src={`data:image/png;base64,${this.props.data['image/png']}`}
        />
      );
    } else if (this.props.data['text/plain']) {
      return (
        <pre style={{ overflow: `auto` }}>
          {stringOrJoin(this.props.data['text/plain'])}
        </pre>
      );
    }

    let view_key = 'application/vnd.jupyter.widget-view+json';

    // Currently also called for non widgets data things.
    if (!this.props.data[view_key]) {
      return <React.Fragment />;
    }

    let model_id = this.props.data[view_key]['model_id'];
    let iframe_url = `${this.props.widgets_root_path}/${model_id}.html`;
    if (this.props.widgets_size_map[model_id] !== 0) {
      return (
        <LazyIFrame
          iframe_url={iframe_url}
          widget_size={this.props.widgets_size_map[model_id]}
        />
      );
    }

    return (
      <Message size="small" color="red">
        There should be something here! The notebook has been saved without
        widget output.
      </Message>
    );
  }
}

interface NotebookCellOutputExecuteResultProps {
  text: [string];
}
class NotebookCellOutputExecuteResult extends React.Component<
  NotebookCellOutputExecuteResultProps,
  {}
> {
  public render() {
    return (
      <pre style={{ overflow: `auto` }}>{stringOrJoin(this.props.text)}</pre>
    );
  }
}

interface NotebookCellHTMLOutputExecuteResultProps {
  html: [string];
}
class NotebookCellHTMLOutputExecuteResult extends React.Component<
  NotebookCellHTMLOutputExecuteResultProps,
  {}
> {
  public render() {
    return (
      <div
        css={{ overflowY: 'auto' }}
        dangerouslySetInnerHTML={{
          __html: stringOrJoin(this.props.html),
        }}
      ></div>
    );
  }
}

interface NotebookCellOutputStreamProps {
  output_type: string;
  name: [string];
}
class NotebookCellOutputStream extends React.Component<
  NotebookCellOutputStreamProps,
  {}
> {
  public render() {
    return (
      <pre
        css={{ overflowY: 'auto' }}
        dangerouslySetInnerHTML={{
          __html: ansiUp.ansi_to_html(stringOrJoin(this.props.text)),
        }}
      ></pre>
    );
  }
}

interface NotebookCellCollectionProps {
  cells: [NotebookCellProps];
  widgets_root_path: string;
  widgets_size_map: { string: number };
  pathname: string;
}

class NotebookCellOutput extends React.Component<NotebookCellOutputProps, {}> {
  public render() {
    if (this.props.output_type === 'display_data') {
      return (
        <NotebookCellOutputDisplayData
          data={this.props.data}
          widgets_root_path={this.props.widgets_root_path}
          widgets_size_map={this.props.widgets_size_map}
        />
      );
    } else if (this.props.output_type === 'stream') {
      return (
        <NotebookCellOutputStream
          text={this.props.text}
          name={this.props.name}
        />
      );
    } else if (this.props.output_type === 'execute_result') {
      return (
        <>
          {Object.entries(this.props.data).map(([key, value]) => {
            if (key === 'text/plain') {
              return <NotebookCellOutputExecuteResult key={key} text={value} />;
            } else if (key == 'text/html') {
              return (
                <NotebookCellHTMLOutputExecuteResult key={key} html={value} />
              );
            }
          })}
        </>
      );
    }
    assert(false);
  }
}

class NotebookCell extends React.Component<NotebookCellProps, {}> {
  public render() {
    if (this.props.outputs) {
      return (
        <Segment
          basic
          size="small"
          style={{ padding: '5px', marginTop: '2em', marginBottom: '2em' }}
        >
          {this.render_cell()}
          {this.render_output()}
        </Segment>
      );
    } else {
      return <React.Fragment>{this.render_cell()}</React.Fragment>;
    }
  }

  render_output() {
    if (!this.props.outputs || this.props.outputs.length == 0) {
      return <React.Fragment />;
    }

    // Filter each output individually.
    let outputs = this.props.outputs.filter((item) => {
      // Empty text.
      if (item.name === 'stdout' && item.text.length == 0) {
        return false;
      } else if (
        // Empty data.
        item.output_type === 'display_data' &&
        Object.keys(item.data).length == 0
      ) {
        return false;
      }
      return true;
    });

    // One last check.
    if (outputs.length == 0) {
      return <React.Fragment />;
    }

    return (
      <Segment>
        {outputs.map((item, index) => (
          <NotebookCellOutput
            key={index}
            output_type={item.output_type}
            data={item.data}
            text={item.text}
            widgets_root_path={this.props.widgets_root_path}
            widgets_size_map={this.props.widgets_size_map}
          />
        ))}
      </Segment>
    );
  }

  render_cell() {
    // Embedded videos must be the only content in a markdown cell
    // Something like <video ... src="..." ...>
    // They are discovered via this regular expression.
    let video_tag_regex = /^\s*<video .*src=\"(.*)\".*\>\s*$/;
    if (
      this.props.cell_type === 'markdown' &&
      this.props.source.length === 1 &&
      this.props.source[0].match(video_tag_regex)
    ) {
      let match = this.props.source[0].match(video_tag_regex);
      if (!match) {
        throw 'Invalid video tag';
      }
      let video_path = match[1];
      return (
        // Chrome will only autoplay muted videos.
        <video controls width="100%" autoPlay loop playsInline muted>
          <source src={video_path} type="video/mp4"></source>
        </video>
      );
    }
    // Special handling for youtube cells.
    else if (
      this.props.metadata &&
      this.props.metadata.tags &&
      this.props.metadata.tags.includes('youtube')
    ) {
      let split_string = this.props.source[0].split(':');
      if (split_string.length != 2 || split_string[0].trim() !== 'youtube') {
        throw 'Invalid youtube strings for cell.';
      }
      let ytube_id = split_string[1].trim();
      return (
        <Embed
          placeholder={`https://img.youtube.com/vi/${ytube_id}/0.jpg`}
          source="youtube"
          id={ytube_id}
        />
      );
    }
    // And vimeo.
    else if (
      this.props.metadata &&
      this.props.metadata.tags &&
      this.props.metadata.tags.includes('vimeo')
    ) {
      let split_string = this.props.source[0].split(':');
      if (split_string.length != 2 || split_string[0].trim() !== 'vimeo') {
        throw 'Invalid vimeo strings for cell.';
      }
      let vimeo_id = split_string[1].trim();
      return (
        <Embed
          // This relies on a third-party services but seems necessary:
          // https://stackoverflow.com/questions/61839510/
          placeholder={`https://vumbnail.com/${vimeo_id}.jpg`}
          source="vimeo"
          id={vimeo_id}
          autoplay={true}
        />
      );
    }
    // Markdown cells.
    else if (this.props.cell_type === 'markdown') {
      // Replace .ipynb links with links to the rendered website.
      return (
        <div>
          {renderMarkdown(
            stringOrJoin(this.props.source).replace(
              /\[([a-zA-Z0-9_-\s:&]*)\]\(([a-zA-Z0-9_-]*)(\.ipynb)\)/g,
              '[$1]($2)'
            ),
            this.props.pathname
          )}
        </div>
      );
    }
    // Code cells.
    else if (this.props.cell_type == 'code') {
      return (
        <Highlighter className="python">
          {stringOrJoin(this.props.source)}
        </Highlighter>
      );
    } else {
      return <React.Fragment />;
    }
  }
}

class NotebookCellCollection extends React.Component<
  NotebookCellCollectionProps,
  {}
> {
  public render() {
    return (
      <div>
        {this.props.cells.map((cell, index) => (
          <NotebookCell
            key={index}
            metadata={cell.metadata}
            cell_type={cell.cell_type}
            source={cell.source}
            outputs={cell.outputs}
            widgets_root_path={this.props.widgets_root_path}
            widgets_size_map={this.props.widgets_size_map}
            pathname={this.props.pathname}
          />
        ))}
      </div>
    );
  }
}

interface NotebookPageProps {
  // Passed on by onCreateNode().
  data: {
    site: {
      siteMetadata: {
        salvusDocVersions: {
          current: string;
        };
      };
    };
    jupyterNotebook: {
      notebook_widgets_root_path: string;
      notebook_json: string;
      notebook_widget_size_map: string;
      path_to_zip_file: string;
      zip_file_size: string;
    };
  };
}

class NotebookPage extends React.Component<
  NotebookPageProps & { location: { pathname: string } },
  {}
> {
  public render() {
    const d = this.props.data.jupyterNotebook;
    // This is passed as a string and then parsed as GraphQL is really not
    // suitable for that.
    const jupyter_notebook = JSON.parse(d.notebook_json);

    const jupyter_notebook_widget_size_map = JSON.parse(
      d.notebook_widget_size_map
    );

    // Version string. Either the number or "LATEST" for the latest version.
    let version = versionNumberFromDocPath(this.props.location.pathname);
    if (
      version == this.props.data.site.siteMetadata.salvusDocVersions.current
    ) {
      version = 'LATEST';
    }

    return (
      <Container>
        {version != 'LATEST' && (
          <Message
            size="mini"
            color="orange"
            content={
              <div>
                <p>
                  This documentation is not for the latest stable Salvus
                  version.
                </p>
              </div>
            }
          />
        )}
        <Button
          css={{
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5) !important',
            marginBottom: '4ex !important',
            marginTop: '4ex !important',
          }}
          color="purple"
          size="medium"
          onClick={() => {
            // Trigger a download by setting the window location to the file URL
            window.location.href = d.path_to_zip_file;
          }}
        >
          <Icon name="download" />
          Download this tutorial as a Jupyter Notebook including data [{d.zip_file_size}]
        </Button>
        <div ref="notebook">
          <NotebookCellCollection
            cells={jupyter_notebook.cells}
            widgets_root_path={
              this.props.data.jupyterNotebook.notebook_widgets_root_path
            }
            widgets_size_map={jupyter_notebook_widget_size_map}
            pathname={this.props.location.pathname}
          />
        </div>
      </Container>
    );
  }

  public componentDidMount() {
    let pageLinks = [];
    // This is a bit nasty but for the dynamically rendered notebooks we do not
    // really have another option. We parse the resulting DOM from this node
    // and extract all headers to be able to build the table of contents.
    for (let node of this.refs.notebook.getElementsByTagName('a')) {
      if (!node.id) {
        continue;
      }
      pageLinks.push({
        link: `#${node.id}`,
        level: parseInt(node.parentNode.tagName.slice(1)),
        text: node.nextSibling.text,
      });
    }

    // Call a function on the parent to update the table of contents.
    this.props.updateTableOfContentsFct(pageLinks);
  }

  public componentDidUpdate() {
    this.componentDidMount();
  }
}

export default withDocLayout(NotebookPage);

export const query = graphql`
  query ($slug: String!) {
    site {
      siteMetadata {
        salvusDocVersions {
          current
        }
      }
    }
    jupyterNotebook(slug: { eq: $slug }) {
      slug
      notebook_widgets_root_path
      notebook_json
      notebook_widget_size_map
      path_to_zip_file
      zip_file_size
    }
  }
`;
