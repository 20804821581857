/**
 *
 * Copy of a this:
 * https://github.com/zeroasterisk/react-iframe-resizer-super/pull/20
 *
 * The react module is pretty outdated and uses an old React version.
 *
 */

import * as React from 'react';
import { Button, Segment, Message } from 'semantic-ui-react';
import ReactIframeResizer from './react_iframe_resizer_super';

interface LazyIFrameProps {
  iframe_url: string;
  widget_size: number;
}

export class LazyIFrame extends React.Component<LazyIFrameProps, {}> {
  state = {
    loadIFrame: false,
  };

  iframeResizerOptions = {
    checkOrigin: false,
    heightCalculationMethod: 'min',
  };

  // Directly render any widget smaller than this.
  sizeThreshold = 10000;

  loadIFrame = () => {
    this.setState({ loadIFrame: true });
  };

  renderPrompt() {
    return (
      <Segment
        style={{
          display: `flex`,
          flexDirection: `column`,
          justifyContent: `center`,
        }}
      >
        <Message size="tiny" color="purple">
          When run interactively in a Jupyter Notebook, this output cell
          contains a widget. Click the button below to load a preview of it.
          Please note that most interactive functionality does not work without
          a running Python kernel.
        </Message>
        <Button onClick={this.loadIFrame} primary>
          Load Widget
        </Button>
      </Segment>
    );
  }

  renderIFrame() {
    return (
      <ReactIframeResizer
        src={this.props.iframe_url}
        iframeResizerOptions={this.iframeResizerOptions}
      />
    );
  }

  public render() {
    if (this.props.widget_size <= this.sizeThreshold || this.state.loadIFrame) {
      return this.renderIFrame();
    } else {
      return this.renderPrompt();
    }
  }
}
